import { defineStore } from 'pinia'
import { useWebshopsStore } from './webshops'

export const useSavedListStore = defineStore('savedList', {
  state: () => {
    return {
      lists: [],
      loading: false
    }
  },

  actions: {
    async getLists() {
      this.loading = true

      try {
        const response = await handleGet({
          url: `/frontend/lists`
        })

        this.lists = response.data
      } catch (ex) { }

      this.loading = false
    },

    async createList(listName, listDescription, notificationSettings, domainIds) {
      const webshops = useWebshopsStore()

      await handlePost({
        url: '/frontend/lists/create',
        body: {
          listName,
          listDescription,
          domainIds,
          notificationSettings,
          countryCode: webshops.country_code
        },
        errMsg: 'Could not save list',
        successMsg: `${listName} was saved as a list`
      })

      await this.getLists()
    },

    async updateList(listId, listName, listDescription, notificationSettings, noMessage = false) {
      await handlePost({
        url: '/frontend/lists/update',
        body: {
          listId,
          listName,
          listDescription,
          notificationSettings
        },
        errMsg: 'Could not update list',
        successMsg: noMessage ? '' : `${listName} was updated`
      })

      await this.getLists()
    },

    async deleteList(listId, listName) {
      await handlePost({
        url: '/frontend/lists/delete',
        body: {
          listId,
        },
        errMsg: 'Failed to delete list',
        successMsg: `You have deleted the list ${listName}`
      })

      this.getLists()
    },

    async leaveList(listId, listName) {
      await handlePost({
        url: '/frontend/lists/leave',
        body: {
          listId
        },
        errMsg: 'Could not leave list',
        successMsg: `You have left the list ${listName}`
      })

      this.getLists()
    },

    async addToList(listId, domainIds, listName) {
      await handlePost({
        url: '/frontend/lists/add',
        body: {
          listId,
          domainIds
        },
        errMsg: 'Could not add webshops to list',
        successMsg: `${domainIds.length} webshops was added to the list \n ${listName}`
      })
    },

    async removeFromList(listId, domainIds, listName) {
      await handlePost({
        url: '/frontend/lists/remove',
        body: {
          listId,
          domainIds
        },
        errMsg: 'Could not remove webshops from list',
        successMsg: `${domainIds.length} webshops was removed from the list \n ${listName}`
      })
    },

    async addDomainsByFilter(listId, listName, listDescription, notificationSettings) {
      const webshops = useWebshopsStore()

      await handlePost({
        url: '/frontend/lists/add/filter',
        body: {
          listId,
          listName,
          listDescription,
          notificationSettings,
          countryCode: webshops.country_code,
          filters: webshops.filterBody()
        },
        errMsg: 'Could not add selected to list',
        successMsg: `${webshops.total} webshops added to list ${listName}`
      })

      if (!listId) {
        await this.getLists()
      }
    },

    async removeDomainsByFilter(listId, listName) {
      const webshops = useWebshopsStore()

      await handlePost({
        url: '/frontend/lists/remove/filter',
        body: {
          listId,
          filters: webshops.filterBody()
        },
        errMsg: 'Could not remove selected from list',
        successMsg: `${webshops.total} webshops removed from list ${listName}`
      })
    },

    async inviteToList(listId, users, message) {
      return await handlePost({
        url: '/frontend/lists/invite',
        body: {
          listId,
          users,
          message
        },
        errMsg: 'Failed to send invite',
        successMsg: `Invitations sent`
      })
    },
  }
})
